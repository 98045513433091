import dayjs from 'dayjs'

export const roundNumberToStep = (value, step) => {
  step || (step = 1.0)
  const inv = 1.0 / step
  return Math.round(value * inv) / inv
}

export const toNormalForm = str => `${str || ''}`.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
export const toSnakeCase = str => toNormalForm(str)
  .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
  .map(x => x.toLowerCase())
  .join('-')

export const consistentNumericalArrayBetweenMinAndMax = (array) => {
  const max = Math.max(...array)
  const min = Math.min(...array)

  return Array.from({ length: max - min + 1 }, (_, k) => k + min)
}

export const truncateByWords = (text, wordCount = 50, glue = '...') => `${text || ''}`
  .trim()
  .split(' ')
  .slice(0, wordCount)
  .map((e, i) => i < wordCount - 1 ? e : glue)
  .join(' ')

export const truncateTripName = (name, length = 14) => {
  if (!name) return ''
  return name.length > length ? name.substring(0, length) + '...' : name
}

export const nullableArray = array => (array || []).length === 0 ? null : array

/**
 * There's no vanilla js way to have both an AbortController.signal (no timeout feature)
 * and AbortSignal.timeout (no abort feature) on a request
 * @param {*} abortController AbortController instance
 * @param {*} ms Abort after ms
 * @returns Clearable timeout instance
 */
export const abortTimeout = (abortController, ms) =>
  process.browser &&
  setTimeout(() => abortController?.signal.aborted ? null : abortController.abort(), ms)

/**
 * Creates an AbortSignal that automatically aborts after a specified timeout.
 *
 * @param {number} timeout - The timeout in milliseconds after which the signal will be aborted.
 * @returns {AbortSignal} - The AbortSignal that will be aborted after the specified timeout.
 */
export function createTimeoutSignal (timeout) {
  const controller = new AbortController()
  setTimeout(() => controller?.signal.aborted ? null : controller.abort(), timeout)
  return controller.signal
}

/**
 * @param {string} dateString ex 2023-06-22 00:00:00
 * @returns {string}
 */
export const shortDate = dateString =>
  dayjs(dateString).format('D/M/YY')

/**
 * Replace DOS newlines (\r) with UNIX / HTML interpreter newlines (\n)
 * Useful for:
 * - Using css "white-space: pre-line" will render newlines
 * - Fixing text hydration mismatch caused by text from FileMaker with \r being removed/ignored by browser
 * @param {string | object | array<object | string>} value \r as newline symbol
 * @param {array<string>} objectKeys keys in value object/value array item object to process
 * @returns {typeof @param value} \n as newline symbol
*/
export const toUnixNewlines = (value, objectKeys = []) => {
  if (!value) {
    return value
  }

  const unixNewlinesString = str => str.replace(/\r/g, '\n')
  const unixNewlinesObjStrings = obj => ({
    ...obj,
    ...Object.entries(obj)
      .filter(([key]) => objectKeys.includes(key))
      .reduce((unixNewlinesObjStrings, [key, val]) => ({
        ...unixNewlinesObjStrings,
        [key]: unixNewlinesString(val),
      }), {}),
  })

  if (typeof value === 'string') {
    return unixNewlinesString(value)
  }

  if (Array.isArray(value)) {
    return value.map((item) => {
      if (!item) {
        return item
      }

      if (typeof item === 'object') {
        return unixNewlinesObjStrings(item)
      }

      return unixNewlinesString(item)
    })
  }

  if (typeof value === 'object') {
    return unixNewlinesObjStrings(value)
  }

  return value
}

export const isMP4 = url =>
  url.match(/\.mp4/g)

/**
 * Randomize order of array entries
 * @param {array} array to randomize order
 * @returns array with randomized order
 */
export const shuffleArray = array => array
  .map(value => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value)

const htmlEvaluator = (html) => {
  if (!process?.browser) {
    return null
  }
  const cleanedHtml = `${html || ''}`
    .replaceAll('\r', '\n')
    .replaceAll(' & ', ' &amp; ')
  const element = document.createElement('div')
  element.innerHTML = cleanedHtml

  return {
    element,
    cleaned: cleanedHtml,
    raw: html,
    isValid: element.innerHTML === cleanedHtml,
  }
}

export const stringIsValidHtml = html => htmlEvaluator(html)?.isValid || false

export const stringContainsHtml = (html) => {
  const evaluation = htmlEvaluator(html)

  if (!evaluation) {
    return false
  }

  for (let c = evaluation.element.childNodes, i = c.length; i--;) {
    if (c[i].nodeType === 1) { return true }
  }

  return false
}

export function loadScript (src, id, callback) {
  const existingScriptElem = document.getElementById(id)

  if (existingScriptElem) {
    if (existingScriptElem.loaded) {
      callback()
    } else {
      existingScriptElem.addEventListener('load', callback)
    }
    return
  }

  const scriptElem = document.createElement('script')
  scriptElem.addEventListener('load', () => {
    scriptElem.loaded = true
    callback()
  })

  scriptElem.setAttribute('src', src)
  scriptElem.setAttribute('id', id)
  document.head.appendChild(scriptElem)
}

export const formatNumber = num => num?.toLocaleString('fr')

export const isNumberEven = num => num % 2 === 0

export const findActiveSlugKey = (localeURLs = {}, params = {}, slug = 'tab') => Object.keys(localeURLs).find(t => localeURLs[t].match(params[slug]))

export function findKeyBySlug (slug, slugs) {
  const formattedSlug = `/${slug}`
  for (const key in slugs) {
    if (slugs[key] === formattedSlug) {
      return key
    }
  }
  return null // Return null if no match is found
}

export const promiseUntilCondition = (condition, opts = {}) => new Promise((resolve, reject) => {
  if (condition()) {
    resolve()
  }

  const { retries, interval, abortSignal } = {
    retries: 200,
    interval: 150,
    abortSignal: null,
    ...opts,
  }

  let i = 0
  const checkConditionInterval = window.setInterval(() => {
    i++
    if (abortSignal?.aborted) {
      reject(new DOMException('Aborted', 'AbortError'))
    } else if (i > retries) {
      reject(new Error(`Could not fill condition: ${condition.toString()}`))
    } else if (condition()) {
      resolve()
      clearInterval(checkConditionInterval)
    }
  }, interval)
})

export const capitalizeFirstLetter = s => !!s && typeof s === 'string' ? s.charAt(0).toUpperCase() + s.slice(1) : ''

export const capitalizeWords = (s) => {
  return s.toLowerCase().replace(/\b\w/g, function (char) {
    return char.toUpperCase()
  })
}

//
export const parseURI = (url, trimLeadingPathnameSlash) => {
  const ele = document.createElement('a')
  ele.href = url

  return {
    ...ele,
    pathname: trimLeadingPathnameSlash && ele.pathname[0] === '/'
      ? ele.pathname.slice(1)
      : ele.pathname,
  }
}
//TODO: not working as process is undefined here
export const getEnvLocale = () => process.env.locale || process.env.DEFAULT_LOCALE

export const deepClone = (value) => {
  let result
  try {
    result = JSON.parse(JSON.stringify(value))
  } catch {
    result = Array.isArray(value) ? [ ...(value || []) ] : { ...(value || {}) }
  }

  return result
}

export const parsePreambleToDescription = str => (str || '')
  .replace(/<h1>(.*)<\/h1>/, '')
  .replace(/(<([^>]+)>)/gi, '')
  .replace(/\r/gi, '')

export const timeout = ms => new Promise(resolve => setTimeout(resolve, ms))

export const isEmpty = value => (
  value === null ||
  value === undefined ||
  value === '' ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === 'object' && Object.values(value || {}).length === 0)
)

/**
 * @param {Object} obj
 * @param {String[]} properties
 * @param {Boolean} filterEmpty
 * @returns {Object} obj only with keys from properties
 */
export const getObjectOnlyProperties = (obj, properties, filterEmpty) => {
  if (filterEmpty) {
    properties = properties.filter(key => !isEmpty(obj[key]))
  }

  return properties.reduce((acc, key) => ({
    ...acc,
    [key]: obj[key],
  }), {})
}

export const getTranslateXY = (element) => {
  const style = window.getComputedStyle(element)
  const matrix = new DOMMatrixReadOnly(style.transform)
  return {
    translateX: matrix.m41,
    translateY: matrix.m42,
  }
}

export const transformArrayToDictionary = (array, key = 'id') => (array || [])
  .reduce((b, a) => ({
    ...b,
    [a?.[key]]: a,
  }), {})

/**
 * Split array into specified amount per chunk
 * @param {Array<any>} array
 * @param {number} perChunk
 * @param {boolean} even
 */
export const chunkArray = (array, perChunk, even) => {
  const chunks = []

  array?.forEach((value) => {
    const lastSlide = chunks[chunks.length - 1]

    if (!lastSlide || lastSlide?.length === perChunk) {
      chunks.push([value])
    } else {
      chunks[chunks.length - 1].push(value)
    }
  })

  if (even && chunks.length && chunks[0].length && chunks[chunks.length - 1].length !== perChunk) {
    array
      .slice(0, perChunk - chunks[chunks.length - 1].length)
      .forEach(value => chunks[chunks.length - 1].push(value))
  }

  return chunks
}

/**
 * Split array into specified amount of groups
 * @param {Array<any>} array
 * @param {number} groups
 */
export const groupArray = (array, groups) => {
  const result = []
  const itemsPerGroup = Math.floor(array.length / groups)
  const remainder = array.length % groups

  for (let i = 0; i < groups; i++) {
    const start = i * itemsPerGroup + Math.min(i, remainder)
    const end = start + itemsPerGroup + (i < remainder ? 1 : 0)
    const group = array.slice(start, end)
    result.push(group)
  }

  return result
}

export const uniqueArray = (array, key) => {
  const uniqueArray = []

  array.forEach(
    isEmpty(key)
      ? (item) => {
        if (!uniqueArray.includes(item)) {
          uniqueArray.push(item)
        }
      }
      : (item) => {
        if (uniqueArray.every(uniqueItem => uniqueItem[key] !== item[key])) {
          uniqueArray.push(item)
        }
      }
  )

  return uniqueArray
}

export const trimByWord = (str, limit = 160) => {
  if (typeof str !== 'string' || str.length <= limit) {
    return str
  }

  return str.substring(0, str.lastIndexOf(' ', limit))
}

export const removeTags = (str) => {
  if ((str === null) || (str === ''))
    return false
  else
    str = str.toString()

  str = str.replace(/(<([^>]+)>)/ig, '')
  return str.trimStart()
}

/**
 * Does not abort promise
 */
export const waitMaxMs = (promise, ms) =>
  process.browser &&
  new Promise((resolve, reject) => {
    const waitUntilReject = setTimeout(reject, ms)

    promise
      .then((returns) => {
        clearTimeout(waitUntilReject)
        resolve(returns)
      })
      .catch(reject)
  })

export const waitMs = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const generateId = () => Math.round(Math.random() * 1e15).toString()

export const isBodyOverflowHidden = () => [...document.body.classList.values()]
  .includes('overflow-hidden')

export const setLongTimeout = (callback, duration) => {
  const timeout = { id: null }

  const runNextInterval = (remainingTime) => {
    if (remainingTime > 2147483647) {
      timeout.id = setTimeout(() => {
        runNextInterval(remainingTime - 2147483647)
      }, 2147483647)
    } else {
      timeout.id = setTimeout(callback, remainingTime)
    }
  }

  runNextInterval(duration)
  return timeout
}