// Migrated
<template>
  <div
    v-rtg-tooltip="{ text: $t('close'), position: 'bottom' }"
    class="cursor-pointer mb-0 absolute top-0 z-[3] modal-close-btn"
    :class="[alignClasses, sizeClass, positionClass, shadow && 'drop-shadow-dark']"
    @click="$emit('click')"
  >
    <fa-layers>
      <fa
        v-if="backgroundColor !== 'transparent'"
        icon="circle"
        :class="`text-${backgroundColor}`"
        transform="shrink-1"
      />
      <fa
        icon="circle-xmark"
        :class="`text-${color}`"
      />
    </fa-layers>
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    /**
     * @prop {'inside' | 'border' | 'outside'}
     */
    align: {
      type: String,
      default: 'inside',
    },

    color: {
      type: String,
      default: 'white',
    },

    backgroundColor: {
      type: String,
      default: 'transparent',
    },

    shadow: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: 'sm',
    },

    buttonLeft: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  computed: {
    alignClasses () {
      if (this.align === 'border') {
        if (this.buttonLeft) {
          return '-mt-3 -ml-3'
        }
        return '-mt-3 -mr-3'
      }
      if (this.align === 'outside') {
        return 'md:-mr-16 md:-mt-16 pt-2 pr-2'
      }
      return ''
    },

    positionClass () {
      if (this.buttonLeft) {
        return 'left-0'
      }
      return 'right-0'
    },

    sizeClass () {
      if (this.size === 'lg') {
        return 'text-[2.5rem]'
      }

      return 'text-[2.5rem]'
    },
  },
})
</script>
