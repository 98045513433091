import * as Sentry from '@sentry/vue'
import {
  extraErrorDataIntegration,
  rewriteFramesIntegration,
  reportingObserverIntegration,
  httpClientIntegration,
} from '@sentry/integrations'

import { IGNORE_ERRORS } from '@layers/web/constants/sentry.ts'

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.server) {
    const event = useRequestEvent()
    const sentry = event.context.$sentry

    return {
      provide: {
        sentrySetContext: sentry.setContext,
        sentrySetUser: sentry.setUser,
        sentrySetTag: sentry.setTag,
        sentryAddBreadcrumb: sentry.addBreadcrumb,
        sentryCaptureException: sentry.captureException,
        sentryCaptureMessage: sentry.captureMessage,
      },
    }
  }

  const { public: { SENTRY } } = useRuntimeConfig()

  const userStore = useUserStore()
  const {
    isLoggedIn,
    user,
    getToken,
  } = storeToRefs(userStore)

  if (
    !SENTRY.dsn ||
    SENTRY.envName === 'development'
  ) {
    return {
      provide: {
        sentrySetContext:
          (...params: Array<any>) => console.info('[Sentry Client] Set context: ', ...params),
        sentrySetUser:
          (...params: Array<any>) => console.info('[Sentry Client] Set user: ', ...params),
        sentrySetTag:
          (...params: Array<any>) => console.info('[Sentry Client] Set tag: ', ...params),
        sentryAddBreadcrumb:
          (...params: Array<any>) => console.info('[Sentry Client] Add Breadcrumb: ', ...params),
        sentryCaptureException:
          (...params: Array<any>) => console.error('[Sentry Client] Captured exception: ', ...params),
        sentryCaptureMessage:
          (...params: Array<any>) => console.error('[Sentry Client] Captured message: ', ...params),
      },
    }
  }

  const { vueApp } = nuxtApp
  const router = useRouter()
  Sentry.init({
    app: [vueApp],
    dsn: SENTRY.dsn,
    environment: SENTRY.environment,
    enabled: true,
    release: SENTRY.release,
    ignoreErrors: IGNORE_ERRORS,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.dedupeIntegration(),
      extraErrorDataIntegration(),
      rewriteFramesIntegration(),
      reportingObserverIntegration(),
      httpClientIntegration(),
      // new Sentry.Replay(),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: [
      /^localhost(:\d+)?$/,
      /^https:\/\/(www\.|dev\.)?rolfsbuss\.se$/,
      /^https:\/\/(www\.|dev\.)?solresor\.se$/,
      /^https:\/\/(www\.|dev\.)?rolfsreiser\.no$/,
      /^https:\/\/(www\.|dev\.)?rolfsrejser\.da$/,
    ],
  })

  vueApp.config.errorHandler = (error) => {
    Sentry.captureException(error)
    if (SENTRY.envName === 'development') {
      console.error(error)
    }
  }

  watch(isLoggedIn, (value) => {
    if (value) {
      Sentry.setUser({
        email: user.value?.email,
        jwt: getToken.value,
      })
    }
  }, { immediate: true })

  // TODO: remove and add this to init options instead of this mixin.
  vueApp.mixin(Sentry.createTracingMixins({
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  })),

  Sentry.attachErrorHandler(vueApp, {
    logErrors:
    false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
      sentryCaptureMessage: Sentry.captureMessage,
    },
  }
})
