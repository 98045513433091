const reload = window.location.reload.bind(window.location)

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:chunkError', (e) => {
    console.info('ChunkError reload', e)

    try {
      reload()
    } catch {
      //
    }
  })
})