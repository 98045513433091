export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('rtg-tooltip', (el, binding) => {
    const text = binding.value?.text || binding.value
    el.setAttribute('data-tooltip', text)
    el.classList.add('v-popper__tooltip')

    if (getComputedStyle(el).position === 'static') {
      el.style.position = 'relative'
    }

    const element = document.createElement('div')
    element.innerHTML = text
    element.classList = 'v-popper__tooltip__inner'

    const position = binding?.value?.position || 'top'
    el.classList.add(`v-popper--${position}`)

    el.appendChild(element)
  })

  nuxtApp.vueApp.directive('click-outside', {
    beforeMount (element, binding) {
      // Check that click was outside the el and his children
      element.clickOutsideEvent = function (event) {
        // only listen to left click
        if (event.which !== 1) {
          return
        }

        let fn = binding.value
        let ignore = null

        if (typeof binding.value === 'object') {
          fn = binding.value.fn
          ignore = document.querySelector(binding.value.ignore)
        }

        // and if it did, call method provided in attribute value
        if (
          !(element === event.target || element.contains(event.target)) &&
          (event.target !== ignore && !ignore?.contains(event.target))
        ) {
          fn()
        }
      }
      document?.body?.addEventListener('mouseup', element.clickOutsideEvent)
    },

    beforeUnmount (element) {
      document?.body?.removeEventListener('mouseup', element.clickOutsideEvent)
    },
  })

  nuxtApp.vueApp.directive('focus', {
    // When the bound element is mounted into the DOM...
    mounted (el) {
      el.focus()
    },
  })
})
