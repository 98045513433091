<template>
  <div class="w-full h-[86px] relative algolia-wrapper">
    <ScreenWidthProvider
      v-if="showAlgoliaSearch"
      v-slot="{ isWider }"
      class="w-12/12 algolia-search-bar absolute px-4"
      :class="getSize"
    >
      <AisInstantSearch
        class="h-full"
        :index-name="tripSearchIndex"
        :search-client="customSearchClient"
      >
        <AisConfigure :hits-per-page.camel="5" />
        <AisSearchBox
          ref="searchForm"
          class="h-full"
          :default-value="initialText"
        >
          <AisAutocomplete
            v-click-outside="{ fn: handleClickOutside, ignore: '.ais-InstantSearch' }"
            class="search-wrapper h-full flex flex-row items-center"
          >
            <template #default="{ currentRefinement, indices, refine }">
              <input
                id="articles-search-header"
                ref="algoliaInputRef"
                type="search"
                autocomplete="off"
                data-i18n="searchFieldPlaceholder|searchButtonText|searchStats"
                :class="{ 'search-header': header }"
                :placeholder="isWider('sm') ? $t('searchFieldPlaceholder') : $t('searchButtonText')"
                :value="currentRefinement"
                @keyup.enter="goto(currentRefinement)"
                @input="refine($event.currentTarget.value)"
              />
              <div class="inline-block h-full algolia-search-bar-btn-wrapper">
                <button
                  class="rounded-box uppercase algolia-search-bar-btn btn-bold text-center"
                  :class="$isSol ? 'btn-orange' : 'btn-blue'"
                  type="submit"
                  data-i18n="searchButtonText"
                  @click="goto(currentRefinement)"
                >
                  {{ $t('searchButtonText') }}
                </button>
              </div>
              <div
                v-if="currentRefinement"
                class="autosuggest__results-container !overflow-hidden"
              >
                <ul
                  v-for="index in indices"
                  :key="index.indexId"
                >
                  <li
                    v-for="hit in index.hits"
                    :key="hit.objectID"
                  >
                    <AlgoliaSearchSuggestion
                      class="hover:bg-light-gray"
                      :suggestion="hit"
                      @click="onSelect(hit)"
                    />
                  </li>
                  <AisStats>
                    <template #default="{ nbHits, query }">
                      <p
                        class="border-t font-bold text-black text-center mx-4 mt-1 pt-4 mb-0 pb-3"
                        data-i18n="searchStats"
                        @click="goto(query)"
                        v-html="$t('searchStats', { nbHits, query }, nbHits)"
                      />
                    </template>
                  </AisStats>
                </ul>
              </div>
            </template>
          </AisAutocomplete>
        </AisSearchBox>
      </AisInstantSearch>
    </ScreenWidthProvider>
    <ScreenWidthProvider
      v-show="!showAlgoliaSearch"
      v-slot="{ isWider }"
      class="w-12/12 algolia-search-bar absolute px-4"
      :class="getSize"
    >
      <div class="search-wrapper h-full flex flex-row items-center">
        <client-only>
          <input
            type="search"
            autocomplete="off"
            :placeholder="isWider('sm') ? $t('searchFieldPlaceholder') : $t('searchButtonText')"
            :class="{ 'search-header': header }"
            :value="initialText"
            @keydown="activateAlgoliaSearch"
          />
        </client-only>
        <div class="inline-block h-full algolia-search-bar-btn-wrapper ml-auto">
          <button
            class="rounded-box uppercase algolia-search-bar-btn btn-bold text-center"
            :class="$isSol ? 'btn-orange' : 'btn-blue'"
            type="button"
          >
            {{ $t('searchButtonText') }}
          </button>
        </div>
      </div>
    </ScreenWidthProvider>
  </div>
</template>

<script setup>
import { AisInstantSearch, AisConfigure, AisSearchBox, AisAutocomplete, AisStats } from 'vue-instantsearch/vue3/es'

const { urls: localeURLs } = useLocale()
const route = useRoute()
const algolia = useAlgoliaRef()
const { tripSearchIndex } = useAlgolia()

const searchForm = ref(null)
const algoliaInput = useTemplateRef('algoliaInputRef')
const showAlgoliaSearch = ref(false)

const props = defineProps({
  autofocus: {
    type: Boolean,
    default: false,
  },

  hideOnClickOutside: {
    type: Boolean,
    default: false,
  },

  header: {
    type: Boolean,
    default: false,
  },

  fullSuggestions: {
    type: Boolean,
    default: true,
  },

  size: {
    type: String,
    default: 'sm',
  },

  hits: {
    type: Number,
    default: 5,
  },

  prefill: {
    type: String,
    default: null,
  },
})

const initialText = ref(props.prefill)

const emit = defineEmits(['search', 'hideSearchBar'])

const getSize = computed(() => {
  return `algolia-search-bar-size-${props.size}`
})

const customSearchClient = {
  search (requests) {
    if (requests.every(request => !request.params.query)) {
      return Promise.resolve({ results: [{ hits: [] }] })
    }

    const modifiedRequests = requests.map(request => ({
      ...request,
      params: {
        hitsPerPage: props.hits,
        query: request.params.query,
      },
    }))

    return algolia.search(modifiedRequests)
  },
}

const handleClickOutside = () => {
  if (props.hideOnClickOutside) {
    emit('hideSearchBar')
  }
}

const clearSearch = () => {
  searchForm.value?.state.clear()
}

const onSelect = (selected) => {
  if (selected) {
    navigateTo(selected.url)
    emit('hideSearchBar')
    clearSearch()
  }
}

const goto = (query) => {
  if (!query) {
    return
  }

  if (route.query.q !== query) {
    navigateTo({ path: localeURLs.search, query: { q: query } })
  }

  emit('hideSearchBar')
  clearSearch()
}

const activateAlgoliaSearch = (event) => {
  initialText.value = event.target.value
  showAlgoliaSearch.value = true
}

watch(
  () => props.autofocus,
  (autofocus) => {
    if (autofocus) {
      showAlgoliaSearch.value = true
    }
  },
  { immediate: true }
)

watch(algoliaInput, () => {
  algoliaInput.value.focus()
})
</script>

<style lang="scss">
/*! purgecss start ignore */
[class^='ais-'] {
  font-size: unset;
}
.ais-SearchBox input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
.algolia-search-bar {
  position: relative;
  z-index: 100;
  height: 3.75em;

  @media (max-width: calc(theme('screens.sm') - 1px)) {
    margin-top: 10px;
  }

  &-map {
    @media (max-width: calc(theme('screens.sm') - 1px)) {
      margin-top: 7px;
    }
  }
  .ais-InstantSearch {

    .ais-SearchBox {
      position: relative;
    }
  }

  .search-wrapper {
    box-shadow: theme('boxShadow.lg');
    background: theme('colors.white');
    margin: 0;
    height: 100%;
    @media (max-width: calc(theme('screens.sm') - 1px)) {
      width: 100%;
      margin-bottom: 12px;
    }

    .autosuggest__results-container {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      background-color: white;
      max-height: 500px;
      overflow-y: auto;
      border-radius: 0;
      width: 100%;
      position: absolute;
    }

    &.search-header .autosuggest__results-container {
      width: calc(100% - 2rem);
    }

    .ais-Stats {
      cursor: pointer;
    }
  }

.ais-Autocomplete.search-wrapper:before {
      position: absolute;
      content: "";
      top: 25%;
      left: 20px;
      width: 2px;
      height: 50%;
      background: theme('colors.orange.DEFAULT');

      @media (max-width: theme('screens.lg')) {
        left: 13px;
      }
    }

  .ais-Autocomplete {
    .search-wrapper div[role=combobox] {
      position: absolute;
      height: 100%;
      flex: 1 1 auto;
    }

    .search-wrapper div[role=combobox]:before {
      position: absolute;
      content: "";
      top: 25%;
      left: 20px;
      width: 2px;
      height: 50%;
      background: theme('colors.orange.DEFAULT');

      @media (max-width: theme('screens.lg')) {
        left: 13px;
      }
    }
  }

  label {
    width: 100%;
    position: relative;
    margin-bottom: 0;

    &:before {
      position: absolute;
      content: "";
      top: 25%;
      left: 28px;
      width: 2px;
      height: 50%;
      background: theme('colors.orange.DEFAULT');

      @media (max-width: calc(theme('screens.sm') - 1px)) {
        left: 12px;
      }
    }
  }

  input {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    font-size: 1.25rem;
    line-height: 1.6;
    padding-left: 28px;
    font-weight: bold;

    @media (max-width: calc(theme('screens.sm') - 1px)) {
      font-size: 1rem;
      line-height: 2.67;
      text-align: left;
      color: theme('colors.black');
    }

    &::placeholder {
      font-size: 1.25rem;
      font-weight: bold;
      font-family: theme('fontFamily.secondary');
      color: theme('colors.black');
      opacity: .5;

      @media (max-width: calc(theme('screens.lg') - 1px)) {
        font-size: .875rem;
      }
    }
  }

  &-btn-wrapper {
    padding: 8px 12px 8px 12px;
  }

  &-btn {
    position: relative;
    border: none;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.33;
    height: 100%;
    min-width: 100px;
  }

  &-size-lg {
    height: 86px;

    .algolia-search-bar-btn-wrapper {
      padding: 12px 12px 12px 0;

      .identity--rolfs & {
        padding: 12px 5px 12px 0;
      }
    }

    .algolia-search-bar-btn {
      @media (min-width: calc(theme('screens.lg') - 1px)) {
        padding-left: 56px;
        padding-right: 56px;
        min-width: 150px;
      }

      @media (max-width: calc(theme('screens.lg') - 1px)) {
        padding: 0;
      }
    }

    input {
      padding-left: 36px;
    }
  }
}

.identity--solresor {
  .header-nav-search-form {
    .algolia-wrapper{
      height: 100% !important;
    }
  }

  .side-menu {
    .algolia-search-bar {
      height: 3.75em;
    }
  }
}

.identity--rolfs {
  .header-nav-search-form {
    .algolia-wrapper{
      height: 100% !important;
    }
    .algolia-search-bar {
      input {
        padding-left: 16px;
      }
    }
  }

  .algolia-search-bar {
    border-radius: 0;
    padding: 0;

    .ais-Autocomplete {
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.08);
    }

    .ais-Autocomplete.search-wrapper:before {
      content: none !important;
    }

    input {
      @media (max-width: calc(theme('screens.sm') - 1px)) {
        font-size: 1.18rem !important;
        line-height: 2.67;
        text-align: left;
        padding-left: 20px;
      }

      &::placeholder {
        font-size: 1.25rem !important;
        font-weight: 600;

        @media (max-width: calc(theme('screens.sm') - 1px)) {
          font-size: 1.18rem !important;
        }
      }
    }

    &-btn {
      border-radius: 29px;
      font-size: 25px;
      font-weight: normal;
      text-transform: capitalize !important;
      top: 0 !important;
      right: 10px !important;
      color: white;
    }
  }
}
/*! purgecss end ignore */
</style>
