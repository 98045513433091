// Translated
// Migrated
<template>
  <div class="trip-item rounded-box">
    <article
      class="article-block"
      :style="{ aspectRatio }"
    >
      <nuxt-link
        :to="trip?.url || '#'"
        :class="aspectRatio && 'size-full'"
      >
        <ResponsiveImage
          class="article-block-img flex flex-col trip-image !size-full"
          :image="trip?.main_picture_cloudinary"
          position="absolute"
          :alt="trip?.title || $t('siteTitleBrand')"
          zoomable
          lazy
          :skeleton="loading"
        >
          <img
            v-if="partnerLogoUrl"
            class="absolute trip-partner-logo partner-logo block mt-12"
            :src="partnerLogoUrl"
            :alt="$t('siteTitleBrand')"
            loading="lazy"
          />
          <div
            v-for="version in ['visible', 'hidden']"
            :ref="version === 'hidden' ? 'virtualPriceContainer' : null"
            :key="version"
            class="absolute text-white top-0 mt-4 right-0 flex items-end"
            :class="[version === 'hidden' ? 'invisible' : 'inline-flex', { 'flex-col': version === 'visible' && isPriceBlockBreakline }]"
          >
            <div
              v-if="trip?.price_min"
              class="mt-2 bg-blue inline-flex items-center"
              :class="isPriceBlockBreakline ? 'h-[30px] px-4' : 'h-[40px] pl-4 pr-1'"
            >
              <div class="text-nowrap">
                <fa :icon="trip?.flight_trip ? 'plane' : 'bus'" />
                <span
                  v-if="days"
                  class="ml-2"
                  data-i18n="daysText"
                >{{ $t('daysText', { n: days }) }}</span>
              </div>
            </div>
            <div
              v-if="trip.price_min"
              class="mt-2 bg-blue inline-flex items-center"
              :class="isPriceBlockBreakline ? 'h-[30px] px-4' : 'h-[40px] pr-4'"
            >
              <div class="text-nowrap">
                <small
                  class="ml-1"
                  data-i18n="shortFromPrice"
                >{{ $t('shortFromPrice') }} {{ ' ' }}</small>
                <small
                  v-if="isDiscounted"
                  class="strikethrough before:!border-white mr-1"
                >{{ $n(trip.price_min_without_discount) }}</small>
                <span :class="{ 'font-bold': isDiscounted }">
                  {{ $n(trip.price_min) }}
                </span>
              </div>
            </div>
          </div>
          <div class="absolute top-left">
            <FavouriteIcon :id="trip?.id" />
          </div>
          <div class="mobile-trip-title">
            <div class="block lg:hidden mt-auto flex justify-between items-end">
              <div>
                <div class="block mb-2 ml-2 pl-1">
                  <span
                    v-if="trip?.new_trip"
                    class="text-white bg-blue rounded-[1.25rem] px-4 py-1"
                  >{{ $t('newTrip') }}</span>
                </div>
                <div class="article-block--title m-0">
                  {{ trip?.title }}
                </div>
                <p class="article-block--subtitle mb-1 block lg:hidden">
                  {{ trip?.subtitle }}
                </p>
                <div class="flex lg:hidden flex-row justify-start article-block--days">
                  <div
                    v-if="trip?.reviews_average && trip?.reviews_average.avg"
                    class="ml-0 lg:ml-3"
                  >
                    <LazyStars
                      class="pr-2 pb-1"
                      :score="trip?.reviews_average.avg"
                      classes="product-price-box__stars"
                    />
                  </div>
                  <span
                    class="text-center mr-4"
                    data-i18n="departuresText"
                  >{{ $t('departuresText', trip?.departures) }}</span>
                  <span
                    class="text-center"
                    data-i18n="daysText"
                  >{{ $t('daysText', { n: days }) }}</span>
                </div>
              </div>
            </div>
          </div>
          <aside class="trip-description article-block-footer hidden lg:flex justify-between">
            <div
              v-if="loading"
              class="trip-description-title-block w-full"
            >
              <div class="w-full">
                <b-skeleton
                  as="h5"
                  height="1.5rem"
                  width="30%"
                />
                <b-skeleton
                  as="p"
                  width="60%"
                />
              </div>
              <div class="w-full">
                <b-skeleton
                  class="block"
                  as="span"
                  width="45%"
                />
              </div>
            </div>
            <div
              v-else
              class="trip-description-title-block flex flex-col justify-start w-full"
            >
              <div class="flex flex-col justify-start">
                <div class="inline mb-2">
                  <span
                    v-if="trip?.new_trip"
                    class="text-white bg-blue rounded-[1.25rem] px-4 py-1"
                  >{{ $t('newTrip') }}</span>
                </div>
                <div class="article-block--title m-0">
                  {{ trip?.title }}
                </div>
                <p class="article-block--subtitle">
                  {{ trip?.subtitle }}
                </p>
              </div>
              <div class="flex flex-row justify-start items-center">
                <div
                  v-if="trip?.reviews_average && trip?.reviews_average.avg"
                  class="ml-auto lg:ml-0 flex items-center"
                >
                  <LazyStars
                    class="pr-2 pb-1"
                    :score="trip?.reviews_average.avg"
                    classes="product-price-box__stars"
                  />
                </div>
                <span class="pr-0">
                  {{ $t('departuresText', trip?.departures) }}
                </span>
              </div>
            </div>
          </aside>
        </ResponsiveImage>
      </nuxt-link>
    </article>
  </div>
</template>

<script>
import throttle from 'lodash.throttle'

export default defineNuxtComponent({
  name: 'Trip',

  middleware: 'auth',

  auth: false,

  props: {
    trip: {
      type: Object,
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    aspectRatio: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      throttledShouldPriceBreakline: null,
      isPriceBlockBreakline: false,
      priceBlockBreaklineOffsetPx: 50,
    }
  },

  computed: {
    days () {
      const daysMin = this.trip?.days_min || 0
      const daysMax = this.trip?.days_max

      return daysMin === daysMax ? daysMin : `${daysMin} - ${daysMax}`
    },

    partnerLogoUrl () {
      return this.trip?.partner_logo ? formatCloudinaryImagePath(this.trip?.partner_logo, {
        transformation: { custom: 'w_350,c_thumb' },
        isSol: this.$isSol,
      }) : null
    },

    isDiscounted () {
      return this.trip?.price_min < this.trip?.price_min_without_discount
    },
  },

  mounted () {
    this.shouldPriceBlockBreakline()
    this.throttledShouldPriceBreakline = throttle(this.shouldPriceBlockBreakline, 200)
    window.addEventListener('resize', this.throttledShouldPriceBreakline)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.throttledShouldPriceBreakline)
  },

  methods: {
    shouldPriceBlockBreakline () {
      // !TODO break this out into its own reusable component
      if (this.$refs.virtualPriceContainer?.length) {
        const {
          offsetWidth: priceContainerWidth,
          parentNode: {
            offsetWidth: priceParentWidth,
          },
        } = this.$refs.virtualPriceContainer[0]

        if (priceContainerWidth + this.priceBlockBreaklineOffsetPx > priceParentWidth) {
          this.isPriceBlockBreakline = true
        } else {
          this.isPriceBlockBreakline = false
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.top-left {
  top: 0;
  left: 0;
  z-index: 1;
}
.partner-logo {
  height: auto;
  width: 80%;
  max-width: 300px;
  left: 50% !important;
  transform: translateX(-50%);

  @media (max-width: theme('screens.lg')) {
    padding: 10px 15px 15px 10px;
  }
}
</style>
