<template>
  <div
    v-if="noAspect"
    class="b-skeleton b-skeleton-img b-skeleton-animate-wave"
    :style="aspectStyle"
  />
  <div
    v-else
    class="b-aspect flex"
  >
    <div
      class="b-aspect-sizer grow"
      :style="aspectStyle"
    />
    <div
      class="b-aspect-content grow w-full max-w-full"
      style="margin-left: -100%"
    >
      <div class="b-skeleton b-skeleton-img b-skeleton-animate-wave" />
    </div>
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    noAspect: {
      type: Boolean,
      default: false,
    },

    aspect: {
      type: String,
      default: null,
    },

    height: {
      type: String,
      default: null,
    },

    width: {
      type: String,
      default: null,
    },
  },

  computed: {
    aspectStyle () {
      const style = {}

      if (!this.noAspect) {
        if (!this.aspect) {
          style['padding-bottom'] = '56.25%'
        } else if (this.aspect === '3:1') {
          style['padding-bottom'] = '33.33%'
        }
      }

      if (this.height) {
        style.height = this.height
      }
      if (this.width) {
        style.width = this.width
      }

      return style
    },
  },
})
</script>
