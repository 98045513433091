<template>
  <NuxtLayout :name="setLayout">
    <div class="error_page pb-12">
      <div class="flex flex-wrap -mx-4 error_page_content mt-4">
        <div class="md:w-12/12 flex error_handle_section p-4">
          <div class="error_question mr-6 hide-mobile">
            <i-questmark-404
              filled
              :font-controlled="false"
            />
          </div>
          <div class="error_handler">
            <p>
              {{ $t('404suggestions') }}
            </p>
            <ul>
              <li class="_handle mb-0">
                <i18n-t
                  data-i18n="404suggestionVisitStart"
                  keypath="404suggestionVisitStart"
                >
                  <nuxt-link to="/">
                    {{ $t('startPage') }}
                  </nuxt-link>
                </i18n-t>
              </li>
              <li
                v-if="!$isSol"
                class="_handle mb-0"
              >
                <i18n-t
                  data-i18n="404suggestionBrowseCalendar"
                  keypath="404suggestionBrowseCalendar"
                >
                  <nuxt-link :to="`${localeURLs.calendar}`">
                    {{ $t('calendar') }}
                  </nuxt-link>
                </i18n-t>
              </li>
              <li class="_handle mb-0">
                {{ $t('404suggestionSearch') }}
              </li>
              <li class="_handle mb-0">
                {{ $t('404suggestionGetInspired') }}
              </li>
            </ul>
          </div>
        </div>
        <div class="md:w-12/12 px-0 mt-12">
          <AlgoliaSearchBar class="mb-4" />
          <Trips
            v-if="!$isSol"
            :trips="gridTrips"
          />
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup>
const rootStore = useRootStore()
const menuStore = useMenuStore()

const localeStore = useLocaleStore()
const { getLocaleMessages: localeMessages } = storeToRefs(localeStore)

const tripStore = useTripStore()
const { categoryStart: trips } = storeToRefs(tripStore)

const { t } = useI18n()
const { urls: localeURLs } = useLocale()

defineProps({
  error: {
    type: Object,
    required: true,
  },
})

useHead({
  title: () => `${t('404title')}`,

  script: [{
    innerHTML: () => localeMessages.organizationData,
    type: 'application/ld+json',
  }],

  meta: [
    {
      hid: 'author',
      name: 'author',
      content: () => t('siteTitleBrand'),
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => `${t('siteTitleBrand')}`,
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: () => `${t('siteTitleBrand')}`,
    },
  ],
})

const setLayout = computed(() => isSol() ? 'charter-page' : 'banner-default')

const firstTrip = computed(() => trips.value[0])

const gridTrips = computed(() => trips.value.filter(trip => trip.id !== firstTrip.value.id).slice(0, 9))

const { $isSol } = useNuxtApp()

await useAsyncData(async () => {
  try {
    await Promise.all([
      tripStore.fetchCategoryStart,
      ...($isSol ? [] : [rootStore.fetchTripTypesSidebar()]),
      rootStore.fetchTopNavbar(),
      rootStore.fetchFooter(),
      rootStore.fetchAlert(),
    ])
  } catch {
    //
  }

  rootStore.SET_PAGE_BANNER({
    title: t('404oops'),
    subtitle: t('404subtitle'),
    type: 'small',
  })

  menuStore.closeSideMenuActive()
})
</script>

<style lang="scss">
@import "assets/scss/modules/_error";
</style>
