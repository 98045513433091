// Migrated
<template>
  <div class="contents">
    <slot v-if="hasPermission" />
    <slot
      v-else
      name="noPermission"
    />
    <slot
      v-if="!agentCookie"
      name="noAgent"
    />
  </div>
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  props: {
    /**
     * List of keys to be truthy in the agentCookie object
     */
    requiredPermissions: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState(useRootStore, ['agentCookie']),

    hasPermission () {
      return (
        this.agentCookie &&
        this.requiredPermissions.every(agentCookieKey => !!this.agentCookie[agentCookieKey])
      )
    },
  },
})
</script>
