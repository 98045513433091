<template>
  <div>
    <NuxtPage />
    <slot />
    <LazyCognitoContainer v-if="locale === 'sv'" />
    <CookieConsent />
    <NewsletterPopup v-if="locale === 'no'" />
    <AgentComponent>
      <LazyAgentCall />
      <LazyTranslationTools />
    </AgentComponent>
  </div>
</template>

<script setup>
const { locale } = useLocaleStore()
</script>