// Migrated
<template>
  <a
    class="flex text-black py-2 w-full min-h-[50px]"
    :class="{ charter: $isSol }"
  >
    <div class="w-1/12 m-auto text-center px-3">
      <fa
        size="lg"
        :icon="formattedSuggestion.icon"
      />
    </div>
    <div class="w-7/12 lg:w-8/12 flex justify-center flex-col px-3">
      <div class="font-bold">
        {{ formattedSuggestion.title }}
      </div>
      <div class="truncate text-smaller">
        {{ formattedSuggestion.subtitle }}
      </div>
      <div
        v-if="formattedSuggestion.departures || formattedSuggestion.days"
        class="inline-flex text-smaller flex-wrap"
      >
        <div
          v-if="formattedSuggestion.departures"
          class="mr-4"
          data-i18n="departuresText"
        >{{ $t('departuresText', formattedSuggestion.departures) }}</div>
        <div
          v-if="formattedSuggestion.days"
          data-i18n="daysText"
        >{{ $t('daysText', { n: formattedSuggestion.days } ) }}</div>
      </div>
    </div>
    <div class="w-4/12 lg:w-3/12 text-right m-auto px-3">
      <template v-if="formattedSuggestion.price">
        <small data-i18n="shortFromPrice">
          {{ $t('shortFromPrice') }}
        </small>
        <span class="text-nowrap">
          &nbsp;{{ $n(formattedSuggestion.price) }}&nbsp;
        </span>
      </template>
    </div>
  </a>
</template>

<script setup lang="ts">
import { SEARCH_TYPES_ICONS } from '@layers/web/constants/search.ts'
import type { AlgoliaSearchResult } from '@layers/web/types/algolia.ts'

type Props = {
  suggestion: AlgoliaSearchResult
}

const props = defineProps<Props>()

const { days } = useDaysMixin()

const formattedSuggestion = computed(() => {
  switch (props.suggestion.type) {
    case 'trip':
    case 'hotel':
    case 'destination': {
      const {
        title,
        flight_trip,
        price,
        price_min,
        departures,
        charter,
        subtitle,
      } = props.suggestion

      return {
        title,
        subtitle,
        icon: flight_trip
          ? SEARCH_TYPES_ICONS.trip_flight
          : SEARCH_TYPES_ICONS.trip,
        price: price || price_min,
        departures,
        days: charter
          ? null
          : days(props.suggestion),
      }
    }

    case 'category': {
      const {
        title,
        preamble_html,
      } = props.suggestion

      return {
        title,
        subtitle: stripHTML(preamble_html),
        icon: SEARCH_TYPES_ICONS.category,
      }
    }

    case 'page': {
      const {
        topic,
        content,
      } = props.suggestion

      return {
        title: topic,
        subtitle: content,
        icon: SEARCH_TYPES_ICONS.page,
      }
    }

    case 'hardcoded': {
      const {
        title,
        description,
      } = props.suggestion

      return {
        title,
        subtitle: description,
        icon: SEARCH_TYPES_ICONS.page,
      }
    }

    case 'web_campaign': {
      const {
        topic,
        html,
      } = props.suggestion

      return {
        title: topic,
        subtitle: stripHTML(html),
        icon: SEARCH_TYPES_ICONS.web_campaign,
      }
    }

    case 'rolf-hotel': {
      const {
        name,
      } = props.suggestion

      return {
        title: name,
        icon: SEARCH_TYPES_ICONS['rolf-hotel'],
      }
    }
  }

  return {}
})
</script>

<style lang="scss">
// vue-autosuggest / algolia
.identity--rolfs {
  .autosuggest__results {
    &-container {
      position: absolute;
      top: calc(100% - .2rem);
      width: 100% !important;
    }

    .usage-info {
      font-size: 0.75rem;
      border-bottom: 1px solid theme('colors.light-gray');
    }
    &-item {
      padding: 0 !important;
      border: none !important;

      &--highlighted {
        .tripsuggestion {
          background-color: theme('colors.light-gray');
          color: theme('colors.orange.DEFAULT');
        }
      }
    }

    .text-smaller {
      font-size: 0.875rem;
    }

    @media (max-width: theme('screens.sm')) {
      font-size: 0.875rem;
      overflow-x: hidden;

      .text-smaller {
        font-size: 0.8rem;
      }
    }
  }
}

.autosuggest__results {
  &-container {
    position: absolute;
    top: calc(100% - .2rem);
    width: 100%;
  }

  .usage-info {
    font-size: 0.75rem;
    border-bottom: 1px solid theme('colors.light-gray');
  }
  &-item {
    padding: 0 !important;
    border: none !important;

    &--highlighted {
      .tripsuggestion {
        background-color: theme('colors.light-gray');
        color: theme('colors.orange.DEFAULT');
      }
    }
  }

  .text-smaller {
    font-size: 0.875rem;
  }

  @media (max-width: theme('screens.sm')) {
    font-size: 0.875rem;
    overflow-x: hidden;

    .text-smaller {
      font-size: 0.8rem;
    }
  }
}
</style>