// Translated
// Migrated
<template>
  <div class="w-12/12 article-grid">
    <div
      v-for="(ctrips, index) in filteredTripsColumns"
      :key="`${index}_${ctrips[0].id}`"
      class="flex flex-wrap -mx-4"
    >
      <div
        v-if="ctrips[0]"
        class="lg:w-8/12 px-4"
      >
        <Trip
          :trip="ctrips[0]"
          size="wide"
        />
      </div>
      <div
        v-if="ctrips[1]"
        class="lg:w-4/12 px-4"
      >
        <Trip :trip="ctrips[1]" />
      </div>
      <template v-if="short">
        <div
          v-for="trip in ctrips.slice(2, 5)"
          :key="`${index}_${trip.id}`"
          class="lg:w-4/12 px-4"
        >
          <Trip :trip="trip" />
        </div>
      </template>
      <template v-if="!short && index % 2 != 0">
        <div
          v-if="ctrips[2]"
          :key="`${index}_${ctrips[2].id}`"
          class="lg:w-4/12 px-4 lg:pb-1em"
        >
          <Trip
            class="high-item"
            size="high"
            :trip="ctrips[2]"
          />
        </div>
        <div
          v-if="ctrips[3]"
          :key="`${index}_${ctrips[3].id}`"
          class="lg:w-4/12 px-4"
        >
          <Trip :trip="ctrips[3]" />
          <Trip
            v-if="ctrips[4]"
            :trip="ctrips[4]"
          />
        </div>
        <div
          v-if="ctrips[5]"
          :key="`${index}_${ctrips[5].id}`"
          class="lg:w-4/12 px-4"
        >
          <Trip :trip="ctrips[5]" />
          <Trip
            v-if="ctrips[6]"
            :trip="ctrips[6]"
          />
        </div>
      </template>
      <template v-if="!short && index % 2 == 0">
        <div
          v-if="ctrips[3]"
          :key="`${index}_${ctrips[3].id}`"
          class="lg:w-4/12 px-4"
        >
          <Trip :trip="ctrips[3]" />
          <Trip
            v-if="ctrips[4]"
            :trip="ctrips[4]"
          />
        </div>
        <div
          v-if="ctrips[5]"
          :key="`${index}_${ctrips[5].id}`"
          class="lg:w-4/12 px-4"
        >
          <Trip :trip="ctrips[5]" />
          <Trip
            v-if="ctrips[6]"
            :trip="ctrips[6]"
          />
        </div>
        <div
          v-if="ctrips[2]"
          :key="`${index}_${ctrips[2].id}`"
          class="lg:w-4/12 px-4 lg:pb-1em"
        >
          <Trip
            class="high-item"
            size="high"
            :trip="ctrips[2]"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    short: {
      type: Boolean,
      default: false,
    },

    trips: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    filteredTripsColumns () {
      if (!this.trips?.length) {
        return []
      }

      const trips = []
      const pageSize = 7

      for (let i = 0; i < this.trips.length / pageSize; i++) {
        trips.push(this.trips.slice(i * pageSize, (i + 1) * pageSize))
      }

      return trips
    },
  },
})
</script>
