// Translated
// Migrated
<template>
  <div
    :class="wrapperClass"
    data-loading="true"
  >
    <div :class="parentClass">
      <LazyLottie
        v-if="$isSol && !charterSpinner"
        name="solresorLoading"
        :autoplay="autoplay"
        :loop="loop"
        :width="width"
      />
      <div
        v-else
        class="box-text text-center"
      >
        <div
          class="spinner-grow text-primary"
          :class="charterSpinner ? 'mt-1' : '.mb-5'"
        />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    autoplay: {
      type: Boolean,
      default: true,
    },

    loop: {
      type: Boolean,
      default: true,
    },

    width: {
      type: Number,
      default: 200,
    },

    parentClass: {
      type: String,
      default: 'py-4 flex justify-center',
    },

    wrapperClass: {
      type: String,
      default: 'text-center',
    },

    charterSpinner: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.spinner-xl {
  .spinner-grow {
    width: 3rem;
    height: 3rem;
  }
}
</style>
