import screens from '#tailwind-config/theme/screens'

export const getTailwindBreakpointsPx: () => {
  [key: string]: number
} = () => {
  return Object.entries(screens)
    .reduce((breakpointsPx, [name, screen]) => ({
      ...breakpointsPx,
      [name]: parseInt(screen),
    }), { 'xs': 0 })
}