import mitt from 'mitt'

const emitter = mitt()

export default defineNuxtPlugin(() => {
  return {
    provide: {
      event: {
        $on: emitter.on,
        $off: emitter.off,
        $emit: emitter.emit,
      },
    },
  }
})