// Provide reusable utilities for reactive properties dependent on screen width
// Migrated
<template>
  <div>
    <slot v-bind="slotProps" />
  </div>
</template>

<script>
import _throttle from 'lodash.throttle'

export default defineNuxtComponent({
  name: 'ScreenWidthProvider',

  props: {
    throttle: {
      type: [Number, Boolean],
      default: 200,
    },
  },

  emits: ['update'],

  data () {
    return {
      windowWidth: null,
      listener: null,
    }
  },

  computed: {
    tailwindBreakpointsPx () {
      return getTailwindBreakpointsPx()
    },

    xs () {
      return this.windowWidth <= 0
    },

    sm () {
      return this.windowWidth > 0 && this.windowWidth <= this.tailwindBreakpointsPx.sm
    },

    md () {
      return this.windowWidth > this.tailwindBreakpointsPx.sm && this.windowWidth <= this.tailwindBreakpointsPx.md
    },

    lg () {
      return this.windowWidth > this.tailwindBreakpointsPx.md && this.windowWidth <= this.tailwindBreakpointsPx.lg
    },

    xl () {
      return this.windowWidth > this.tailwindBreakpointsPx.lg && this.windowWidth <= this.tailwindBreakpointsPx.xl
    },

    '2xl' () {
      return this.windowWidth > this.tailwindBreakpointsPx.xl && this.windowWidth <= this.tailwindBreakpointsPx['2xl']
    },

    '3xl' () {
      return this.windowWidth > this.tailwindBreakpointsPx['2xl'] && this.windowWidth <= this.tailwindBreakpointsPx['3xl']
    },

    slotProps () {
      return {
        width: this.windowWidth,
        xs: this.xs,
        sm: this.sm,
        md: this.md,
        lg: this.lg,
        xl: this.xl,
        '2xl': this['2xl'],
        '3xl': this['3xl'],
        isWider: this.isWider,
        isNarrower: this.isNarrower,
        isBetween: this.isBetween,
      }
    },
  },

  mounted () {
    this.updateWidth()
    this.listener = this.throttle ? _throttle(this.updateWidth, this.throttle) : this.updateWidth
    window.addEventListener('resize', this.listener)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.listener)
  },

  methods: {
    updateWidth () {
      if (window.innerWidth === this.windowWidth) {
        return
      }
      this.windowWidth = window.innerWidth
      this.$emit('update', this.windowWidth)
    },

    isWider (size, offset = 0) {
      return typeof size === 'number' ? this.windowWidth + offset >= size : this.windowWidth + offset > this.tailwindBreakpointsPx[size]
    },

    isNarrower (size, offset = 0) {
      return typeof size === 'number' ? this.windowWidth + offset < size : this.windowWidth + offset <= this.tailwindBreakpointsPx[size]
    },

    isBetween (size1, size2) {
      let val1 = typeof size1 === 'number' ? size1 : this.tailwindBreakpointsPx[size1]
      let val2 = typeof size2 === 'number' ? size2 : this.tailwindBreakpointsPx[size2]

      if (val1 > val2) {
        [val1, val2] = [val2, val1]
      }

      return this.isWider(val1) && this.isNarrower(val2)
    },
  },
})
</script>
